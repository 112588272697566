import React from 'react';
import BackToConfigurator from './BackToConfigurator';
import NextStepButton from './NextStepButton';
import FabricSelectedIcon from 'images/fabric-selected-check.svg';
import cx from 'classnames';

export default function AttributeSelectionPanel({
  productName,
  attributeName,
  options,
  selectedAttribute,
  setSelectedAttribute,
  setCurrentScreen,
  currencySymbol,
  nextAttribute,
  nextAttributeName,
  previousAttribute
}) {
  const attributeTitle =
    attributeName.charAt(0).toUpperCase() + attributeName.slice(1);

  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options">
            <div className="fc-selection-panel-title-row">
              <div className="col fc-selection-panel-title">
                Select a {attributeTitle}
              </div>
            </div>
          </div>
          <div className="row fc-selection-pallet fc-custom-selection-pallet d-flex align-items-start">
            <div className="row no-gutters fc-fabric-pallet-row w-100">
              {options &&
                options.map((attribute) => {
                  return (
                    <AttributeSelection
                      key={attribute.id}
                      attribute={attribute}
                      isSelected={attribute === selectedAttribute}
                      selectedAttribute={selectedAttribute}
                      setSelectedAttribute={setSelectedAttribute}
                    />
                  );
                })}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={!selectedAttribute}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
          />
        </div>
      </div>
    </div>
  );
}

export function AttributeSelection({
  attribute,
  isSelected,
  selectedAttribute,
  setSelectedAttribute
}) {
  const [isActive, setIsActive] = React.useState(null);
  const containerClasses = cx(
    'row no-gutters fc-custom-selection',
    (isActive || isSelected) && 'fc-custom-selection-active'
  );
  const imageSource = attribute.images.display;
  const handleClick = () => {
    if (selectedAttribute != attribute) {
      setSelectedAttribute(attribute);
    }
  };

  return (
    <div
      className={containerClasses}
      onMouseLeave={() => setIsActive(false)}
      onMouseEnter={() => setIsActive(true)}
      onClick={handleClick}
    >
      <div className="col fc-selection-sketch">
        <img
          src={imageSource}
          className="fc-sketch-image"
          alt={attribute.name}
        />
      </div>

      <div className="col fc-selection-title">
        <div className="">{attribute.name.toLowerCase()}</div>
      </div>
      <span className="fc-selected-sign">
        {isSelected && <img src={FabricSelectedIcon} alt="Selected" />}
      </span>
    </div>
  );
}
