import React from 'react';
import NextStepButton from './NextStepButton';
import BackToConfigurator from './BackToConfigurator';
import SubSelectionPalletColor from './SubSelectionPalletColor';
import SubSelectionPalletLocation from './SubSelectionPalletLocation';
import MonogramPalletStyle from './MonogramPalletStyle';
import cx from 'classnames';

export default function MonogramSelectionPanel({
  productName,
  currencySymbol,
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  setCurrentScreen,
  isDisabled,
  selectableOptionsTable,
  setSelectedAttributeFunctions,
  userSelections,
  clearMonogram,
  monogramError,
  monogramCategories,
  monogramStylesSpecs,
  selectedMonogramStyleSpecs,
  monogramInitials,
  setMonogramInitials,
  setWasInMonogramSection
}) {
  const enabled = userSelections.monogram_initials !== null;

  const palletComponents = {
    monogram_style: MonogramPalletStyle,
    monogram_color: SubSelectionPalletColor,
    monogram_location: SubSelectionPalletLocation
  };

  const toggleMonogram = () => {
    if (enabled) {
      clearMonogram();
    } else {
      const options = selectableOptionsTable['monogram_initials'];
      setSelectedAttributeFunctions['monogram_initials'](options?.[0]);
    }
  };

  let characterRegExp = new RegExp(
    `[^${selectedMonogramStyleSpecs.validCharacters}]`,
    'gi'
  );

  const pallets = monogramCategories.map((category) => {
    if (category === 'monogram_initials') {
      return (
        <div className="monogram-initials" key={category}>
          <span className="monogram-selection-header">Enter Initials</span>
          <p className="initials-description">
            maximum characters vary by style
          </p>
          <input
            type="input"
            id="input-initials"
            value={monogramInitials}
            className="monogram-input-initials"
            onInput={(e) => {
              e.target.value = e.target.value.replace(characterRegExp, '');
            }}
            style={{ textTransform: selectedMonogramStyleSpecs.textTransform }}
            onChange={(e) => setMonogramInitials(e.target.value)}
            maxLength={selectedMonogramStyleSpecs.max}
          />
          {monogramError ?
            <div className="error">
              <p className="errorText">{monogramError}</p>
            </div>
          : null}
        </div>
      );
    }

    let Pallet = palletComponents[category];
    // Don't show monogram styles if they have only a single option
    if (
      Pallet &&
      !(
        category === 'monogram_style' &&
        selectableOptionsTable[category].length < 2
      )
    ) {
      return (
        <Pallet
          key={category}
          options={selectableOptionsTable[category]}
          selectedAttribute={userSelections[category]}
          setSelectedAttribute={setSelectedAttributeFunctions[category]}
          monogramStylesSpecs={monogramStylesSpecs}
        />
      );
    } else {
      return null;
    }
  });

  const containerClasses = cx(
    'row no-gutters fc-fabric-selection-pallet',
    enabled ?
      'monogram-selection-pallet-scrollbar'
    : 'monogram-selection-w-pallet-scrollbar'
  );

  React.useEffect(() => {
    setWasInMonogramSection(true);
  }, []);

  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options monogram">
            <div className="fc-selection-panel-title-row">
              <div className="col fc-selection-panel-title">
                Select a Monogram
              </div>
            </div>
          </div>

          <div className={containerClasses}>
            <div className="row no-gutters fc-fabric-pallet-row monogram-panel">
              <label
                className="monogram-checkbox"
                style={
                  !enabled ?
                    { backgroundColor: 'white' }
                  : { backgroundColor: '#ececec' }
                }
              >
                <input
                  type="checkbox"
                  defaultChecked={enabled}
                  onChange={toggleMonogram}
                />
                <div className="monogram-checkbox-check"></div>
                <span className="fc-selection-panel-title monogram-panel-title">
                  Yes, Add Monogram (Free)
                </span>
              </label>

              {!enabled ?
                <p className="monogram-text">
                  A monogram is another way to emphasize that this shirt was
                  made for you, by you.
                  <br />
                  Choose from several styles, colors, and placements.
                </p>
              : null}

              {enabled ?
                <div className="row no-gutters fc-fabric-pallet-row">
                  {pallets}
                </div>
              : null}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={isDisabled}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
          />
        </div>
      </div>
    </div>
  );
}
