import React from 'react';

export default function NextStepButton({
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  isDisabled,
  productName,
  setCurrentScreen
}) {
  const nextScreen = nextAttribute || 'main';
  const previousScreen = previousAttribute || 'main';
  const handleNextClick = () => {
    setCurrentScreen(nextScreen);
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
  };
  const handlePreviousClick = () => {
    setCurrentScreen(previousScreen);
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
  };

  return (
    <div className="d-flex flex-row fc-configurator-button-container">
      <button
        className="btn fc-btn-configurator-previous btn-default"
        onClick={handlePreviousClick}
      >
        Previous
      </button>

      <button
        className="btn btn-default fc-btn-configurator-step"
        onClick={handleNextClick}
        disabled={isDisabled}
      >
        {nextAttribute
          ? `Continue to ${nextAttributeName || nextAttribute}`
          : `Finalize my ${productName}`}
      </button>
    </div>
  );
}
