import React from 'react';
import BackToConfigurator from './BackToConfigurator';
import NextStepButton from './NextStepButton';
import FabricSelectedIcon from 'images/fabric-selected-check.svg';
import cx from 'classnames';

export default function FitSelectionPanel({
  productName,
  fitOptions,
  selectedFit,
  setCurrentScreen,
  setSelectedFit,
  resetSelections,
  currencySymbol,
  nextAttribute,
  previousAttribute,
  isNextDisabled
}) {
  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options">
            <div className="fc-selection-panel-title-row">
              <div className="col fc-selection-panel-title">Select a Fit</div>
            </div>
          </div>
          <div className="row fc-selection-pallet fc-custom-selection-pallet d-flex align-items-start">
            <div className="row no-gutters fc-fabric-pallet-row w-100">
              {fitOptions &&
                fitOptions.map((fit) => {
                  return (
                    <FitSelection
                      key={fit.id}
                      fit={fit}
                      isSelected={fit === selectedFit}
                      setSelectedFit={setSelectedFit}
                      resetSelections={resetSelections}
                    />
                  );
                })}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            attributeName={'fit'}
            nextAttribute={nextAttribute}
            previousAttribute={previousAttribute}
            isDisabled={isNextDisabled}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
          />
        </div>
      </div>
    </div>
  );
}

export function FitSelection({
  fit,
  isSelected,
  setSelectedFit,
  resetSelections
}) {
  const [isActive, setIsActive] = React.useState(null);
  const containerClasses = cx(
    'row no-gutters fc-custom-selection',
    (isActive || isSelected) && 'fc-custom-selection-active'
  );
  const handleClick = () => {
    if (!isSelected) {
      setSelectedFit(fit);
      resetSelections();
    }
  };

  return (
    <div
      className={containerClasses}
      onMouseLeave={() => setIsActive(false)}
      onMouseEnter={() => setIsActive(true)}
      onClick={handleClick}
    >
      <div className="col fc-fit-selection-sketch">
        <img src={fit.image} className="fc-fit-sketch-image" alt={fit.answer} />
      </div>

      <div className="col fc-selection-title">
        <div className="fc-fit-selection-title">{fit.answer}</div>

        <div className="fc-fit-selection-description pt-1">
          {fit.description}
        </div>
      </div>
      <span className="fc-selected-sign">
        {isSelected && <img src={FabricSelectedIcon} alt="Selected" />}
      </span>
    </div>
  );
}
