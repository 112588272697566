const addToCart = async ({
  cartApi,
  configured_item,
  quantity,
  description,
  descriptions,
  preview,
  selectedBody,
  cartRestoreData,
  iframeUrl
}) => {
  const hasScan = !!selectedBody['scanId'];

  const iframeEl = document.querySelector('.fc-iframe-shopify-ess');

  if (configured_item.id && iframeEl && cartRestoreData) {
    let { ['Sized For']: sizedFor, ...configurations } =
      Object.fromEntries(descriptions);
    if (sizedFor === 'undefined undefined') sizedFor = '(Provided at checkout)';

    const { cart, newItem } = cartRestoreData;

    iframeEl.contentWindow.postMessage(
      {
        action: 'setOrderCache',
        body: [
          ...cart,
          {
            ...newItem,
            quantity,
            properties: {
              _formcutConfiguredItemId: configured_item.id,
              _formcutConfiguredItemIsComplete: hasScan,
              _formcutConfiguredItemPreview: preview,
              'Sized For': sizedFor,
              ...configurations
            }
          }
        ]
      },
      iframeUrl
    );
  }

  const [skuProductId, sku] = configured_item.sku.split(':');

  // Timeout added only for testing, to make sure that the previous
  // event has been acted upon.
  setTimeout(() => {
    window.parent.postMessage(
      {
        action: 'addCustomCommerceItem',
        body: {
          productId: skuProductId,
          catalogRefId: sku,
          quantity,
          configuredItemId: configured_item.id,
          display: {
            configurations: Object.fromEntries(descriptions),
            thumbnail_img_url: preview
          },
          description,
          hasScan
        }
      },
      cartApi
    );
  }, 5000);
};

const updateInCart = async ({
  configuredItemIds,
  selectedBody,
  authenticityToken,
  cartApi,
  descriptions
}) => {
  const res = await window.fetch(`/configured_items/bulk_update`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken
    },
    body: JSON.stringify({
      configured_item_ids: configuredItemIds,
      selected_body: selectedBody
    }),
    method: 'PATCH'
  });

  if (res.ok) {
    window.parent.postMessage(
      {
        action: 'configuredItemIsComplete',
        body: {
          configuredItemIds,
          display: {
            configurations: Object.fromEntries(descriptions)
          }
        }
      },
      cartApi
    );
  }
};

export const iframePostMessageCartService = {
  addToCart,
  updateInCart
};
