import React, { useEffect, useRef, useState } from 'react';
import NextStepButton from './NextStepButton';
import BackToConfigurator from './BackToConfigurator';

export default function BodySelectionPanel({
  productName,
  currencySymbol,
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  iframeUrl,
  setCurrentScreen,
  selectedBody,
  updateSelectedBody
}) {
  const iframeRef = useRef();
  const iframeSrc = useRef(
    selectedBody.userId ?
      iframeUrl + '/#/customers/' + selectedBody.userId
    : iframeUrl
  );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.source !== iframeRef.current.contentWindow) return;
      updateSelectedBody(event.data);
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options monogram">
            <div className="fc-selection-panel-title-row">
              <div className="col fc-selection-panel-title">
                Provide Measurements
              </div>
            </div>
          </div>

          <iframe
            ref={iframeRef}
            className="fc-iframe-selection-panel"
            src={iframeSrc.current}
          />
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={!selectedBody.scanId}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
          />
        </div>
      </div>
    </div>
  );
}
