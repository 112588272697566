import React from 'react';
import NextStepButton from './NextStepButton';
import BackToConfigurator from './BackToConfigurator';
import SubSelectionPalletColor from './SubSelectionPalletColor';
import SubSelectionPalletLocation from './SubSelectionPalletLocation';
import { CONTRAST_STITCHING_CATEGORIES_ARRAY } from '../constants/contrast_stitching';
import cx from 'classnames';

const palletComponents = {
  contrast_stitching_color: SubSelectionPalletColor,
  contrast_stitching_location: SubSelectionPalletLocation
};

export default function ContrastStitchingSelectionPanel({
  productName,
  currencySymbol,
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  setCurrentScreen,
  isDisabled,
  selectableOptionsTable,
  unselectableOptionsTable,
  setSelectedAttributeFunctions,
  userSelections,
  enabled,
  setEnabled,
  wasInContrastStitchingSection,
  setWasInContrastStitchingSection
}) {
  const containerClasses = cx(
    'row no-gutters fc-fabric-selection-pallet',
    enabled ?
      'monogram-selection-pallet-scrollbar'
    : 'monogram-selection-w-pallet-scrollbar'
  );

  const toggleCheckbox = () => {
    if (enabled) {
      setEnabled(false);
      CONTRAST_STITCHING_CATEGORIES_ARRAY.forEach((category) => {
        setSelectedAttributeFunctions[category](
          unselectableOptionsTable[category][0]
        );
      });
    } else {
      setEnabled(true);
      CONTRAST_STITCHING_CATEGORIES_ARRAY.forEach((category) => {
        setSelectedAttributeFunctions[category]();
      });
    }
  };

  React.useEffect(() => {
    setWasInContrastStitchingSection(true);

    // Set the selection to be none initially, unless there is a selection present already.
    if (userSelections && !wasInContrastStitchingSection) {
      CONTRAST_STITCHING_CATEGORIES_ARRAY.forEach((category) => {
        !!userSelections[category] ||
          setSelectedAttributeFunctions[category](
            unselectableOptionsTable[category][0]
          );
      });
    }
  }, []);

  return (
    <div className="fc-selection-panel fc-custom-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row fc-back-to-configurator-row">
            <BackToConfigurator
              setCurrentScreen={setCurrentScreen}
              currencySymbol={currencySymbol}
            />
          </div>
          <div className="row fc-selection-panel-options fc-custom-selection-panel-options monogram">
            <div className="fc-selection-panel-title-row">
              <div className="col fc-selection-panel-title">
                Select a Contrast Stitching
              </div>
            </div>
          </div>

          <div className={containerClasses}>
            <div className="row no-gutters fc-fabric-pallet-row monogram-panel">
              <label
                className="monogram-checkbox"
                style={
                  !enabled ?
                    { backgroundColor: 'white' }
                  : { backgroundColor: '#ececec' }
                }
              >
                <input
                  type="checkbox"
                  defaultChecked={enabled}
                  onChange={toggleCheckbox}
                />
                <div className="monogram-checkbox-check"></div>
                <span className="fc-selection-panel-title monogram-panel-title">
                  Yes, Add Contrast Stitching (Free)
                </span>
              </label>

              {!enabled ?
                <p className="monogram-text">
                  Choose from several colors and placements.
                </p>
              : null}

              {enabled ?
                <div className="row no-gutters fc-fabric-pallet-row">
                  {Object.keys(palletComponents).map((category) => {
                    const Pallet = palletComponents[category];
                    return Pallet ?
                        <Pallet
                          key={category}
                          options={selectableOptionsTable[category]}
                          selectedAttribute={userSelections[category]}
                          setSelectedAttribute={
                            setSelectedAttributeFunctions[category]
                          }
                        />
                      : <></>;
                  })}
                </div>
              : null}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={isDisabled}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
          />
        </div>
      </div>
    </div>
  );
}
