import React, { useEffect, useRef, useState } from 'react';
import { iframePostMessageCartService } from '../actions/cart-services/iframePostMessage-cart';
import { usePostedMessageForwarder } from '../hooks/postedMessageForwarder';

const MOBILE_SCREEN = 450;

export default function PopupBodySelector({
  iframeUrl,
  updateSelectedBody,
  isEssOnly,
  essQueryParams,
  configuredItemIds,
  authenticityToken,
  cartApi
}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const iframeRef = useRef();
  const iframeQueryParams = new URLSearchParams(essQueryParams);
  if (isEssOnly) {
    iframeQueryParams.append('view', 'ess-only');
  }
  const iframeSrc = useRef(iframeUrl + `?${iframeQueryParams.toString()}`);
  const isMobile = screenWidth <= MOBILE_SCREEN;

  usePostedMessageForwarder(iframeUrl, cartApi);

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.origin !== iframeUrl) return;
      if (event.data.action === 'essIframeResize') {
        iframeRef.current.style.width = event.data.body.width;
        iframeRef.current.style.height = event.data.body.height;

        let left = '0px',
          right = '0px';

        if (event.data.body.event === 'modalOpen') {
          right = 'auto';
        } else if (
          event.data.body.event === 'accountBtnCollapsed' ||
          event.data.body.event === 'accountBtnExpanded'
        ) {
          left = 'auto';
          right = isMobile ? '0px' : '100px';
        }

        iframeRef.current.style.left = left;
        iframeRef.current.style.right = right;
      } else if (event.data.action === 'setUserData') {
        const { orders, ...sb } = event.data.body.userData;
        updateSelectedBody(sb);
        if (isEssOnly) {
          if (orders && orders.length > 0) {
            window.parent.postMessage(
              {
                action: 'cartRestore',
                body: {
                  orders
                }
              },
              cartApi
            );
          }

          await iframePostMessageCartService.updateInCart({
            configuredItemIds,
            descriptions: [['Sized For', sb.firstName + ' ' + sb.lastName]],
            selectedBody: sb,
            authenticityToken,
            cartApi
          });
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      className="fc-iframe-shopify-ess"
      src={iframeSrc.current}
    />
  );
}
