import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import BackToConfigurator from './BackToConfigurator';
import NextStepButton from './NextStepButton';
import FabricInfoIcon from 'images/fabric-info-icon.svg';
import FabricSelectedIcon from 'images/fabric-selected-check.svg';
import DefaultFabricImage from 'images/default-fabric.png';
import SearchIcon from 'images/search.svg';

export default function FabricSelectionPanel({
  productName,
  fabrics,
  hasMoreInfo,
  attributeName,
  setSelectedAttribute,
  selectedAttribute,
  setCurrentScreen,
  currencySymbol,
  nextAttribute,
  nextAttributeName,
  previousAttribute,
  hidePayment,
  showFabricDetail
}) {
  const attributeTitle =
    attributeName.charAt(0).toUpperCase() + attributeName.slice(1);
  const hasFabrics = !!fabrics;
  const [touched, setTouched] = useState(false);
  const [query, setQuery] = useState('');

  const filteredFabrics = useMemo(() => {
    if (query === '' || fabrics === null) {
      return fabrics;
    } else {
      const lowerCasedQuery = query.toLowerCase();
      return fabrics.filter((_fabric) =>
        _fabric.name.toLowerCase().includes(lowerCasedQuery)
      );
    }
  }, [fabrics, query]);

  const handleOnClickSearch = () => {
    setTouched(true);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    setQuery('');
  }, [attributeName]);

  return (
    <div className="fc-selection-panel">
      <div className="d-flex flex-column">
        <div className="fc-selection-panel-content">
          <div className="row no-gutters fc-back-to-configurator-row">
            <BackToConfigurator
              currencySymbol={currencySymbol}
              setCurrentScreen={setCurrentScreen}
            />
          </div>
          <div className="row no-gutters fc-selection-panel-options fc-fabric-panel-options">
            <div className="fc-selection-panel-title-row searchable mb-4">
              <div className="col-12 col-xl-6 fc-selection-panel-title fabric-panel-title">
                Select a {attributeTitle}
              </div>
              <div
                className={classNames(
                  'col-12 col-xl-6 mt-2 mt-xl-0 fc-fabrics-filter',
                  {
                    touched
                  }
                )}
              >
                <input
                  type="search"
                  placeholder="Search"
                  className="search-click"
                  id="fabric-search"
                  onChange={handleSearch}
                  value={query}
                />
                <label htmlFor="fabric-search" onClick={handleOnClickSearch}>
                  <img src={SearchIcon} alt="Search" />
                </label>
              </div>
            </div>
          </div>
          <div className="row no-gutters fc-fabric-selection-pallet">
            <div className="row no-gutters fc-fabric-pallet-row">
              {hasFabrics &&
                filteredFabrics.map((fabric) => {
                  const isSelectedAttribute = selectedAttribute === fabric;
                  return (
                    <FabricPallet
                      key={fabric.id}
                      currencySymbol={currencySymbol}
                      fabric={fabric}
                      hasMoreInfo={hasMoreInfo}
                      isSelectedAttribute={isSelectedAttribute}
                      setSelectedAttribute={setSelectedAttribute}
                      hidePayment={hidePayment}
                      showFabricDetail={showFabricDetail}
                    />
                  );
                })}
            </div>
          </div>
        </div>

        <div className="d-none d-md-block">
          <NextStepButton
            nextAttribute={nextAttribute}
            nextAttributeName={nextAttributeName}
            previousAttribute={previousAttribute}
            isDisabled={!selectedAttribute}
            setCurrentScreen={setCurrentScreen}
            productName={productName}
          />
        </div>
      </div>
    </div>
  );
}

export function FabricPallet({
  currencySymbol,
  fabric,
  setSelectedAttribute,
  isSelectedAttribute,
  hasMoreInfo,
  hidePayment,
  showFabricDetail
}) {
  const [isActive, setIsActive] = React.useState(null);
  const isDetailVisible = (isSelectedAttribute || isActive) && hasMoreInfo;
  const showFabricInfoDetails = showFabricDetail && isDetailVisible;
  const handleFabricSelection = () => {
    if (!isSelectedAttribute) {
      setSelectedAttribute(fabric);
    }
  };

  return (
    <span className="col-lg-4 col-sm-6 fc-fabric-pallet">
      <span
        className="fc-fabric-pallet-image-container"
        onMouseLeave={() => setIsActive(false)}
        onMouseEnter={() => setIsActive(true)}
      >
        {isDetailVisible && (
          <span className="fc-info-sign">
            <img
              src={FabricInfoIcon}
              alt="More information"
              data-toggle="modal"
              data-target={`#FabricDescriptionModal-${fabric.id}`}
            />
          </span>
        )}
        {isSelectedAttribute && (
          <span className="fc-selected-sign">
            <img src={FabricSelectedIcon} alt="Fabric is selected" />
          </span>
        )}
        <div className="fc-fabric-pallet-image" onClick={handleFabricSelection}>
          <img
            src={
              fabric.thumbnail_url ||
              fabric.images.display ||
              DefaultFabricImage
            }
            alt="Fabric preview"
          />
          {showFabricInfoDetails && (
            <div className="fc-info-details d-flex">
              <div className="align-self-center">
                {Object.keys(fabric.attributes).map((attributeTitle) => {
                  const displayTitle =
                    attributeTitle.charAt(0).toUpperCase() +
                    attributeTitle.slice(1);
                  const attributeText = fabric.attributes[attributeTitle];

                  return (
                    <div key={attributeTitle}>
                      <span className="fc-info-detail-title">
                        {displayTitle}:{' '}
                      </span>
                      <span className="fc-info-detail-description">
                        {attributeText}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </span>
      <div className="fc-fabric-title">{fabric.name}</div>
      {!hidePayment && hasMoreInfo && (
        <div className="fc-fabric-cost">
          {currencySymbol}
          {Math.round(fabric.price)}
        </div>
      )}
    </span>
  );
}
