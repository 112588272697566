import React from 'react';
import ConfiguratorHeader from './ConfiguratorHeader';
import BodySelectionRow from './BodySelectionRow';
import FitSelectionRow from './FitSelectionRow';
import FabricSelectionRow from './FabricSelectionRow';
import MonogramSelectionRow from './MonogramSelectionRow';
import CustomAttributeRow from './CustomAttributeRow';
import ContrastStitchingRow from './ContrastStitchingRow';
import OrderTotals from './OrderTotals';
import OrderActions from './OrderActions';
import { CONTRAST_STITCHING_CATEGORIES_SET } from '../constants/contrast_stitching';
import { SHOW_BODY_SELECTOR } from '../constants/body_selector';

export default function ConfiguratorHome({
  itemCost,
  productKey,
  productName,
  selectedBody,
  selectedFit,
  setCurrentScreen,
  canOpenAttributes,
  currencySymbol,
  authenticityToken,
  fitOptions,
  lineItemId,
  userSelections,
  categorySettings,
  categoriesToShow,
  isFitLocked,
  startingQuantity,
  monogramCategories,
  monogramSelections,
  monogramFullySelected,
  monogramInitials,
  hidePayment,
  showBodySelector,
  productExternalId,
  cartSettings,
  contrastStitchingEnabled,
  cartRestoreData,
  iframeUrl
}) {
  const [quantity, setQuantity] = React.useState(startingQuantity);
  const canAddToBasket = !!(
    (cartSettings.cart === 'iframePostMessage' || selectedBody.scanId) &&
    selectedFit &&
    categoriesToShow.every(
      (category) =>
        monogramCategories.includes(category) ||
        userSelections[category] !== null
    ) &&
    monogramFullySelected
  );

  const customAttributeRows = categoriesToShow.map((categoryKey, idx) => {
    const attributeName = categorySettings[categoryKey].display_name;

    if (categoryKey === 'fabric') {
      return (
        <FabricSelectionRow
          key={categoryKey}
          fabric={userSelections[categoryKey]}
          setCurrentScreen={setCurrentScreen}
          canOpen={canOpenAttributes}
        />
      );
    } else if (monogramCategories.includes(categoryKey)) {
      if (categoryKey === 'monogram_initials')
        return (
          <MonogramSelectionRow
            key="monogram"
            canOpen={canOpenAttributes}
            setCurrentScreen={setCurrentScreen}
            monogramInitials={monogramInitials}
            monogramSelections={monogramSelections}
            monogramFullySelected={monogramFullySelected}
          />
        );
    } else {
      const isLastAttribute = categoriesToShow.at(-1) === categoryKey;

      if (CONTRAST_STITCHING_CATEGORIES_SET.has(categoryKey)) {
        if (
          idx !== 0 &&
          CONTRAST_STITCHING_CATEGORIES_SET.has(categoriesToShow[idx - 1])
        ) {
          return;
        }

        return (
          <ContrastStitchingRow
            key={`contrast_stitching`}
            canOpen={canOpenAttributes}
            setCurrentScreen={setCurrentScreen}
            isLastAttribute={isLastAttribute}
            contrastStitchingEnabled={contrastStitchingEnabled}
            userSelections={userSelections}
          />
        );
      }

      return (
        <CustomAttributeRow
          key={categoryKey}
          attributeName={attributeName}
          setCurrentScreen={setCurrentScreen}
          selectedAttribute={userSelections[categoryKey]}
          canOpen={canOpenAttributes}
          panelScreen={categoryKey}
          isLastAttribute={isLastAttribute}
        />
      );
    }
  });

  return (
    <div className="col-md-6 col-sm-12 fc-options-container mr-0">
      <div className="container">
        <div className="fc-options-summary">
          <ConfiguratorHeader
            productName={productName}
            currencySymbol={currencySymbol}
          />
          <div className="fc-options-selections">
            {showBodySelector === SHOW_BODY_SELECTOR.SELECTION && (
              <BodySelectionRow
                isBodyLocked={false}
                selectedBody={selectedBody}
                setCurrentScreen={setCurrentScreen}
                panelScreen={'body'}
              />
            )}

            <FitSelectionRow
              isFitLocked={isFitLocked}
              selectedFit={selectedFit}
              setCurrentScreen={setCurrentScreen}
              panelScreen={'fit'}
              fitOptions={fitOptions}
            />

            {customAttributeRows}

            <OrderTotals
              currencySymbol={currencySymbol}
              itemCost={itemCost}
              quantity={quantity}
              setQuantity={setQuantity}
              isLimitedQuantity={!isFitLocked}
              hidePayment={hidePayment}
            />
            <OrderActions
              authenticityToken={authenticityToken}
              productKey={productKey}
              lineItemId={lineItemId}
              monogramInitials={monogramInitials}
              quantity={quantity}
              canAddToBasket={canAddToBasket}
              userSelections={userSelections}
              selectedFit={selectedFit}
              selectedBody={selectedBody}
              monogramSelections={monogramSelections}
              itemCost={itemCost}
              productExternalId={productExternalId}
              categorySettings={categorySettings}
              categoriesToShow={categoriesToShow}
              cartSettings={cartSettings}
              cartRestoreData={cartRestoreData}
              iframeUrl={iframeUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
